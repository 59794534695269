import request from '../libs/axios'

export function getNominations(params) {
  return request({
    url: '/api/nomination/index',
    method: 'get',
    params,
  })
}

export function storeNomination(data) {
  return request({
    url: '/api/nomination/index',
    method: 'post',
    data,
  });
}

export function showNomination(data) {
  return request({
    url: `/api/nomination/index/${data.id}`,
    method: 'get',
    data,
  });
}

export function updateNomination(data) {
  return request({
    url:`/api/nomination/index/${data.get('id')}`,
    method: 'post',
    data,
  })
}

export function deleteNomination(data) {
  return request({
    url: `/api/nomination/index/${data.id}`,
    method: 'delete',
  });
}

// general questions

export function getNominationQuestions(params) {
  return request({
    url: '/api/nomination/questions',
    method: 'get',
    params,
  })
}

export function storeNominationQuestion(data) {
  return request({
    url: '/api/nomination/questions',
    method: 'post',
    data,
  });
}

export function showNominationQuestion(data) {
  return request({
    url: `/api/nomination/questions/${data.id}`,
    method: 'get',
    data,
  });
}

export function updateNominationQuestion(data) {
  return request({
    url:`/api/nomination/questions/${data.get('id')}`,
    method: 'post',
    data,
  })
}

export function deleteNominationQuestion(data) {
  return request({
    url: `/api/nomination/questions/${data.id}`,
    method: 'delete',
  });
}

// Nomination questions

export function getQuestions(params) {
  return request({
    url: '/api/nomination/new-questions',
    method: 'get',
    params,
  })
}

export function storeQuestion(data) {
  return request({
    url: '/api/nomination/new-questions',
    method: 'post',
    data,
  });
}

export function showQuestion(data) {
  return request({
    url: `/api/nomination/new-questions/${data.id}`,
    method: 'get',
    data,
  });
}

export function updateQuestion(data) {
  return request({
    url:`/api/nomination/new-questions/${data.get('id')}`,
    method: 'post',
    data,
  })
}

export function deleteQuestion(data) {
  return request({
    url: `/api/nomination/new-questions/${data.id}`,
    method: 'delete',
  });
}

export function changeStatus(data) {
  return request({
    url: '/api/nomination/change-status',
    method:'post',
    data,
  })
}

// contents

export function getContents(params) {
  return request({
    url: '/api/nomination/contents',
    method: 'get',
    params,
  })
}

export function storeContent(data) {
  return request({
    url: '/api/nomination/contents',
    method: 'post',
    data,
  });
}

export function showContent(data) {
  return request({
    url: `/api/nomination/contents/${data.id}`,
    method: 'get',
    data,
  });
}

export function updateContent(data) {
  return request({
    url:`/api/nomination/contents/${data.get('id')}`,
    method: 'post',
    data,
  })
}

export function deleteContent(data) {
  return request({
    url: `/api/nomination/contents/${data.id}`,
    method: 'delete',
  });
}

export function generateMarksExcel(params) {
  return request({
    url: '/api/nomination/marks-excel',
    method: 'get',
    params,
    responseType: 'blob'
  })
}

export function getStages() {
  return request({
    url: '/api/stages',
    method: 'get',
  })
}

export function updateApplicationStage(data) {
  return request({
    url: '/api/applications/update-application-stage',
    method: 'post',
    data,
  })
}

