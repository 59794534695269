import request from '../libs/axios'

export function getVariants(params) {
  return request({
    url: '/api/question-variants',
    method: 'get',
    params,
  })
}

export function storeVariant(data) {
  return request({
    url: '/api/question-variants',
    method: 'post',
    data,
  });
}

export function showVariant(data) {
  return request({
    url: `/api/question-variants/${data.id}`,
    method: 'get',
    data,
  });
}

export function updateVariant(data) {
  return request({
    url:`/api/question-variants/${data.get('id')}`,
    method: 'post',
    data,
  })
}

export function deleteVariant(data) {
  return request({
    url: `/api/question-variants/${data.id}`,
    method: 'delete',
  });
}
