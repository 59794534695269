<template>
  <section class="category-form">
    <b-overlay
      :show="loading"
      no-wrap
      spinner-variant="primary"
    />
    <validation-observer
      ref="observer"
      class="w-100"
    >
      <div class="w-100">
        <b-form-group :label="$t('message.QuestionUz')">
          <validation-provider
            #default="{ errors }"
            :name="$t('message.QuestionUz')"
            rules="required"
          >
            <b-input
              v-model="form.question_uz"
              required
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
      <div class="w-100">
        <b-form-group :label="$t('message.QuestionRu')">
          <validation-provider
            #default="{ errors }"
            :name="$t('message.QuestionRu')"
            rules="required"
          >
            <b-input
              v-model="form.question_ru"
              required
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
      <div class="w-100">
        <b-form-group :label="$t('message.QuestionEn')">
          <validation-provider
            #default="{ errors }"
            :name="$t('message.QuestionEn')"
            rules="required"
          >
            <b-input
              v-model="form.question_en"
              required
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
      <div class="w-100 mt-4">
        <b-row>
          <b-col cols="6">
            <b-form-group :label="$t('message.WordLimit')">
              <validation-provider
                  #default="{ errors }"
                  :name="$t('message.WordLimit')"
                  rules="required"
              >
                <b-input
                    type="number"
                    v-model="form.word_limit"
                    required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group :label="$t('message.IsVideo')">
            <b-form-checkbox
                v-model="form.is_video"
                :value="true"
                :unchecked-value="false"
            >
            </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <!--      <div class="w-100">-->
      <!--      <b-form-group :label="$t('message.MarksCount')">-->
      <!--        <b-input type="number" v-model="form.marks_count"></b-input>-->
      <!--      </b-form-group>-->
      <!--      </div>-->
      <h3>Answers</h3>
      <div
        v-for="(val, index) in questionAnswers"
        class="w-100"
      >
        <b-form-group>
          <div class="d-flex">
            <validation-provider
              #default="{ errors }"
              :name="$t('message.QuestionEn')"
              rules="required"
              class="w-100"
            >
              <b-input v-model="val.title" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <b-button
              variant="danger"
              size="sm"
              @click="removeAnswer(index)">
              <b-icon icon="trash" />
            </b-button>
          </div>
        </b-form-group>
      </div>
      <div class="w-100 d-flex justify-content-end">
        <b-button
          variant="success"
          size="sm"
          @click="increaseAnswers"
        >
          <b-icon icon="plus" />
        </b-button>
      </div>
    </validation-observer>
    <div class="w-100 mt-3 d-flex justify-content-end">
      <b-button
        variant="success"
        @click="saveSubmit"
      >
        {{ $t('message.Add') }}
      </b-button>
    </div>
  </section>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { storeNominationQuestion, updateNominationQuestion, showNominationQuestion } from '@/api/nomination'
import { formTemplate } from '@/utils/mixins/formTemplate'
import { quillEditor } from 'vue-quill-editor'
import vSelect from 'vue-select'
import { getVariants } from '@/api/variants'

function initForm(data) {
  const get = (key, value = null) => _.get(data, key, value)

  return {
    id: get('id', null),
    nomination_id: get('nomination_id', null),
    question_uz: get('question_uz', null),
    question_ru: get('question_ru', null),
    question_en: get('question_en', null),
    marks_count: get('marks_count', null),
    is_video: get('is_video', false),
    word_limit: get('word_limit', null),
    variant_id: get('variant_id', null),
  }
}

const actions = {
  add: storeNominationQuestion,
  update: updateNominationQuestion,
  show: showNominationQuestion,
}

export default {
  name: 'NominationQuestionForm',
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect
  },
  mixins: [
    formTemplate,
  ],
  props: {
    variants: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      actions,
      required,
      snowOption: {
        theme: 'snow',
      },
      form: initForm(),
      allCategories: [],
      allTypes: [],
      answersCount: 1,
      questionAnswers: [{ title: '' }],
    }
  },
  methods: {
    reformatData() {
      this.form = {
        ...this.form,
        ...{
          answers: this.questionAnswers,
          nomination_id: this.$route.params.id ? this.$route.params.id : null,
          stage_id: this.$route.params.stageId,
          variant_id: this.$route.params.variantId,
        },
      }
      const form = this.prepareFormData(this.form)
      return form
    },
    setForm(data) {
      if (data) {
        this.questionAnswers = data.answers
      }
      this.form = initForm(data)
    },
    increaseAnswers() {
      this.questionAnswers.push({ title: '' })
    },
    removeAnswer(index) {
      this.questionAnswers.splice(index, 1)
    },
  },
}
</script>

<style scoped>

</style>
