var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"category-form"},[_c('b-overlay',{attrs:{"show":_vm.loading,"no-wrap":"","spinner-variant":"primary"}}),_c('validation-observer',{ref:"observer",staticClass:"w-100"},[_c('div',{staticClass:"w-100"},[_c('b-form-group',{attrs:{"label":_vm.$t('message.QuestionUz')}},[_c('validation-provider',{attrs:{"name":_vm.$t('message.QuestionUz'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"required":""},model:{value:(_vm.form.question_uz),callback:function ($$v) {_vm.$set(_vm.form, "question_uz", $$v)},expression:"form.question_uz"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"w-100"},[_c('b-form-group',{attrs:{"label":_vm.$t('message.QuestionRu')}},[_c('validation-provider',{attrs:{"name":_vm.$t('message.QuestionRu'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"required":""},model:{value:(_vm.form.question_ru),callback:function ($$v) {_vm.$set(_vm.form, "question_ru", $$v)},expression:"form.question_ru"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"w-100"},[_c('b-form-group',{attrs:{"label":_vm.$t('message.QuestionEn')}},[_c('validation-provider',{attrs:{"name":_vm.$t('message.QuestionEn'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"required":""},model:{value:(_vm.form.question_en),callback:function ($$v) {_vm.$set(_vm.form, "question_en", $$v)},expression:"form.question_en"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"w-100 mt-4"},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('message.WordLimit')}},[_c('validation-provider',{attrs:{"name":_vm.$t('message.WordLimit'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"number","required":""},model:{value:(_vm.form.word_limit),callback:function ($$v) {_vm.$set(_vm.form, "word_limit", $$v)},expression:"form.word_limit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('message.IsVideo')}},[_c('b-form-checkbox',{attrs:{"value":true,"unchecked-value":false},model:{value:(_vm.form.is_video),callback:function ($$v) {_vm.$set(_vm.form, "is_video", $$v)},expression:"form.is_video"}})],1)],1)],1)],1),_c('h3',[_vm._v("Answers")]),_vm._l((_vm.questionAnswers),function(val,index){return _c('div',{staticClass:"w-100"},[_c('b-form-group',[_c('div',{staticClass:"d-flex"},[_c('validation-provider',{staticClass:"w-100",attrs:{"name":_vm.$t('message.QuestionEn'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{model:{value:(val.title),callback:function ($$v) {_vm.$set(val, "title", $$v)},expression:"val.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.removeAnswer(index)}}},[_c('b-icon',{attrs:{"icon":"trash"}})],1)],1)])],1)}),_c('div',{staticClass:"w-100 d-flex justify-content-end"},[_c('b-button',{attrs:{"variant":"success","size":"sm"},on:{"click":_vm.increaseAnswers}},[_c('b-icon',{attrs:{"icon":"plus"}})],1)],1)],2),_c('div',{staticClass:"w-100 mt-3 d-flex justify-content-end"},[_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.saveSubmit}},[_vm._v(" "+_vm._s(_vm.$t('message.Add'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }